import React from "react"
import { Helmet } from "react-helmet"

const Seo = ({
	title,
	description
}) => {

	const formattedTitle = title ? `${title} – WeLoveNoise` : 'WeLoveNoise'

  return (
    <Helmet title={formattedTitle}>
      <meta name="description" content={description} />

      {title && <meta property="og:title" content={`${title} - WeLoveNoise`} />}
			{title && <meta name="twitter:title" content={`${title} - WeLoveNoise`} />}

      {description && <meta property="og:description" content={description} />}
			{description && <meta name="twitter:description" content={description} />}

			<meta property='og:url' content='https:/welovenoise.com/'/>
    </Helmet>
  )
}

export default Seo